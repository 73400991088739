import React from 'react'
import { Link } from 'react-router-dom'

const ComingSoon = () => {
    return (
        <div className='crancy-coming-soon__inside h-screen'> 
            <div class="py-28 text-center"> 
                <div class="text-center mb-5">
                    <h2 class="text-center text-7xl font-bold mb-5 text-white">Coming Soon</h2>
                    {/* <img src='https://img.pikbest.com/element_our/20220405/bg/1c936d8b550cd.png' className='lg:w-96 m-auto' alt='' /> */}
                </div> 
                 
            </div> 
        </div>
    )
}

export default ComingSoon